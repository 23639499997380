import { BoxConfiguration, BoxPrice, CameraPrice, DeviceOptions, LicenseTerms, ResolutionOptions, RetentionOptions, StorageOptions } from '@models/pricing.model';

export const PricingTermStr = [
  '1 Year',
  '3 Years',
  '5 Years',
];

export const PricingTermToYears = [
  1,
  3,
  5,
];

export const RetentionOptionsStr = [
  '30 Days',
  '60 Days',
  '90 Days',
  'Cloud Add-ons'
];

export const ResolutionOptionsStr = [
  '5MP',
  '8MP',
];

export const boxConfiguration: BoxConfiguration[] = [
  {retention: RetentionOptions.Days30, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 12},
  {retention: RetentionOptions.Days30, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 8},
  {retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 7},
  {retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 5},
  {retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 2},
  {retention: RetentionOptions.Days30, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 2},
  {retention: RetentionOptions.Days60, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 7},
  {retention: RetentionOptions.Days60, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 5},
  {retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 3},
  {retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 2},
  {retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 2},
  {retention: RetentionOptions.Days60, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 2},
  {retention: RetentionOptions.Days90, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 5},
  {retention: RetentionOptions.Days90, storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 3},
  {retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 2},
  {retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 1},
  {retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: ResolutionOptions.Resolution5MP, maxNumberOfCameras: 2},
  {retention: RetentionOptions.Days90, storage: StorageOptions.Storage2TB, device: DeviceOptions.Nano8GBOrin, resolution: ResolutionOptions.Resolution8MP, maxNumberOfCameras: 1},
];

export const boxPricing: BoxPrice[] = [
  {
    storage: StorageOptions.Storage4TB, device: DeviceOptions.OrinNX16GB, price: {
      [LicenseTerms.Years1]: 749,
      [LicenseTerms.Years3]: 699,
      [LicenseTerms.Years5]: 679,
    },
  },
  {
    storage: StorageOptions.Storage2TB, device: DeviceOptions.OrinNX16GB, price: {
      [LicenseTerms.Years1]: 659,
      [LicenseTerms.Years3]: 609,
      [LicenseTerms.Years5]: 589,
    },
  },
];

export const swLicensePricing: Record<LicenseTerms, number> = {
  [LicenseTerms.Years1]: 199,
  [LicenseTerms.Years3]: 169,
  [LicenseTerms.Years5]: 159,
};

export const cameraPricing: CameraPrice[] = [
  {
    resolution: ResolutionOptions.Resolution5MP, price: {
      [LicenseTerms.Years1]: 32,
      [LicenseTerms.Years3]: 29,
      [LicenseTerms.Years5]: 27,
    },
  },
  {
    resolution: ResolutionOptions.Resolution8MP, price: {
      [LicenseTerms.Years1]: 42,
      [LicenseTerms.Years3]: 39,
      [LicenseTerms.Years5]: 37,
    },
  },
];

