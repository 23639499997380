import { createAction, props } from '@ngrx/store';
import { LicenseTerms, Pricing, PricingCount, PricingLocation } from '@models/pricing.model';

export const resetToInitialState = createAction('[PRICING] Reset to Initial state');

export const setTerm = createAction('[PRICING] Set Term', props<{ term: LicenseTerms }>());
export const addLocation = createAction('[PRICING] Add Location', props<{ location: PricingLocation }>());
export const editLocation = createAction('[PRICING] Edit Location', props<{ index: number, location: PricingLocation }>());
export const calculatePricing = createAction('[PRICING] Calculate Pricing');
export const calculateCountPricing = createAction('[PRICING] Calculate Count Pricing');
export const setPricing = createAction('[PRICING] Set Pricing', props<{ pricing: Pricing, count: PricingCount }>());
export const setCount = createAction('[PRICING] Set Pricing', props<{ count: PricingCount }>());
export const removeLocation = createAction('[PRICING] Remove Location', props<{ index: number }>());
export const incrementCount = createAction('[PRICING] Increment Count', props<{ field: string }>());
export const decrementCount = createAction('[PRICING] Decrement Count', props<{ field: string }>());

// export const setPricing = createAction('[TRACK OBJECT] Set Track Object', props<{ pricingState: PricingState }>());

