export enum LicenseTerms {
  Years1,
  Years3,
  Years5
}

export enum RetentionOptions {
  Days30,
  Days60,
  Days90,
  Cloud_add_ons
}

export enum ResolutionOptions {
  Resolution5MP,
  Resolution8MP
}

export enum StorageOptions {
  Storage1TB,
  Storage2TB,
  Storage4TB
}

export enum DeviceOptions {
  Nano8GBOrin,
  XavierNX16GB,
  OrinNX16GB
}

export interface BoxConfiguration {
  retention: RetentionOptions;
  storage: StorageOptions;
  device: DeviceOptions;
  resolution: ResolutionOptions;
  maxNumberOfCameras: number;
}

export interface BoxPrice {
  storage: StorageOptions;
  device: DeviceOptions;
  price: Record<LicenseTerms, number>;
}

export interface CameraPrice {
  resolution: ResolutionOptions,
  price: Record<LicenseTerms, number>
}

export interface Pricing {
  boxes: number;
  license: number;
  cameras: number;
  cloud?: number;
  total: number;
  regularPrice: number;
}

export interface PricingCount {
  box2tb: number;
  box4tb: number;
  cameras5mp: number;
  cameras8mp: number;
  license: number;
  cloudStorage?: number;
  cloudSmartStorage?: number;
  cloudFlowTagging?: number;
}

export interface PricingLocation {
  name: string;
  numberOfCameras: number;
  retention: RetentionOptions;
  resolution: ResolutionOptions;
  numberOfNewCameras: number;
  terms: LicenseTerms;
}


