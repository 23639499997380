<ng-container *ngrxLet="selectLoadingPulsation; let loading">
  <ng-container *ngIf="!loading && status">
    <div [matTooltip]="tooltipSecond?statusTitle +'\n' + tooltipSecond:statusTitle"
         #wrapper
         fxLayoutAlign="center center"
         [class]="color + ' ' + iconClass"
         [class.no-text]="!text"
         [class.no-bg]="noBg"
         [class.no-padding]="noPadding"
         [class.small]="isSmallTile"
         matTooltipClass="multi-line-tooltip"
    >
      <ng-container *ngIf="showIcon">
        <ng-container [ngSwitch]="status">
          <ng-container *ngSwitchCase="ComponentStatus.NotRecording">
            <mat-icon svgIcon="not-recording"></mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="ComponentStatus.Online">
            <span class="ui-icon-status-online ui-icon-20"></span>
          </ng-container>
          <ng-container *ngSwitchCase="ComponentStatus.Streaming">
            <span class="ui-icon-status-online ui-icon-20"></span>
          </ng-container>
          <ng-container *ngSwitchCase="ComponentStatus.Offline">
            <span class="ui-icon-status-offline ui-icon-20"></span>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <span class="ui-icon-status-unhealthy ui-icon-20"></span>
          </ng-container>
        </ng-container>
      </ng-container>
      <span *ngIf="showStatus"
            class="status-string" [style.font-size]="(fontSize ?? 14) + 'px'"
      >{{ status === ComponentStatus.Online && showTime && playing ? (time$|async) : statusString }}</span>
    </div>
  </ng-container>
  <ng-container *ngIf="(loading || !status) && !hideSpinner">
    <ui-preloader [color]="preloaderColor.blue" [diameter]="16" matTooltip="Unhealthy"
                  matTooltipClass="sidebar-tooltip"
    ></ui-preloader>
  </ng-container>
</ng-container>
